export function getCookieDomain(domain: string): string {
  return (domain || location.hostname).replace(/^(https?:\/\/)?(www\.)?/, '.')
}

export function doesUrlContainParam(key: string, urlParamsObject?: object): boolean {
  urlParamsObject = urlParamsObject || getParamsFromUrl()
  return !!urlParamsObject[key]
}

export function getParamsFromUrl(): object {
  const urlParams = new URLSearchParams(window.location.search)
  return Array.from(urlParams.keys()).length > 0 ? Object.fromEntries(urlParams.entries()) : {}
}

const cookieService = {
  maximalCookieTTL: 90,
  setItem: (key: string, value: string, sameSite = 'None', days = 365, domain = ''): void => {
    const currentDate = new Date()
    currentDate.setTime(+currentDate + days * 86400000)

    document.cookie = `${key}=${value}; path=/; domain=${getCookieDomain(
      domain,
    )}; expires=${currentDate.toUTCString()}; SameSite=${sameSite}; Secure`
  },
  getItem: (name: string): string | null => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    return parts.length === 2 ? parts.pop().split(';').shift() : null
  },
  removeItem(key: string): void {
    document.cookie = `${key}=; path=/`
  },
  removeCookie(key: string, domain?: string | null): void {
    const $zeroTime = new Date(0)
    document.cookie = `${key}=; expires=${$zeroTime.toUTCString()}${domain ? `; domain=${domain}` : ''}; path=/`
  },
  setCookieByParamUrl(paramKey: string, cookieKey: string): void {
    const urlParamsObject = getParamsFromUrl()
    if (doesUrlContainParam(paramKey, urlParamsObject))
      cookieService.setItem(cookieKey, urlParamsObject[paramKey], 'None', cookieService.maximalCookieTTL)
  },
  setupCookiesFromURL: (allowedParamsIds: string[]): void => {
    const paramsToStore = getParamsFromUrl()
    for (const paramName in paramsToStore) {
      if (doesUrlContainParam(paramName, paramsToStore) && allowedParamsIds.includes(paramName)) {
        let cookieName = paramName
        if (paramName === 'xchangerId') cookieName = 'xchanger_id'
        cookieService.setItem(cookieName, paramsToStore[paramName], 'Lax', cookieService.maximalCookieTTL)
      }
    }
  },
  setTrasactCookies(paramKey: string[], cookieKey: string): void {
    const urlParamsObject = getParamsFromUrl()
    if (doesUrlContainParam('ref')) {
      const cookieContent = paramKey.reduce((acc, key) => {
        return doesUrlContainParam(key) && urlParamsObject[key] !== '' ? `${acc}&${key}=${urlParamsObject[key]}` : acc
      }, `${urlParamsObject['ref']}`)
      const finalCookieContent = cookieContent.startsWith('&') ? cookieContent.slice(1) : cookieContent
      cookieService.setItem(cookieKey, finalCookieContent, 'None', cookieService.maximalCookieTTL)
    }
  },
  setupCookiesFromClient: (): void => {
    if (cookieService.getItem('ik_referrer')) {
      // TODO: Check is this code relevant for us.
      cookieService.setItem('ik_referrer', document.referrer, 'None', 730)
    }
  },
}

export default cookieService

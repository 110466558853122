import { getCurrentLanguage } from 'utils/current-language'
import cookieService from './cookie-service'

interface Cookiebot {
  // https://www.cookiebot.com/en/developer/#h-properties
  consent: {
    necessary: boolean
    preferences: boolean
    statistics: boolean
    marketing: boolean
    method: 'implied' | 'explicit' | null
  }
  bulkconsent: {
    preferences: boolean
    statistics: boolean
    marketing: boolean
    ticket: string
    utc: number
  }
  consented: boolean
  bulkConsentEnabled: boolean
  consentID: string
  consentUTC: {
    getTime: () => string
  }
  // https://www.cookiebot.com/en/developer/#h-methods
  // we wont use withdraw() method for reject all cookies because for us preferences cookies are necessary
  // and thats why we should use submitCustomConsent() method over withdraw() method
  submitConsent: () => void
  submitCustomConsent: (preferences: boolean, statistics: boolean, marketing: boolean) => void
  renew: () => void
}

declare global {
  interface Window {
    // object from Cookiebot script
    Cookiebot: Cookiebot
    cookieConsent: object
  }
}

const cookieConsent = {
  // script tag
  $cookiebotScript: document.querySelector<HTMLScriptElement>('#Cookiebot'),
  // containers
  $bannerContainer: document.querySelector<HTMLDivElement>('#cookiebanner__container'),
  $banner: document.querySelector<HTMLElement>('#cookiebanner'),
  $dialog: document.querySelector<HTMLElement>('#cookiedetails'),
  $backdrop: document.querySelector<HTMLDivElement>('.cookiebanner__backdrop'),
  // widget containers
  $widgetContainer: document.querySelector<HTMLElement>('#cookiewidget-container'),
  $widgetDialog: document.querySelector<HTMLElement>('#cookiewidget-dialog'),
  // buttons
  $acceptAllButton: document.querySelector<HTMLButtonElement>('#banner-accept-all-button'),
  $acceptAllDialogButton: document.querySelector<HTMLButtonElement>('#details-accept-all-button'),
  $openDetailsButton: document.querySelector<HTMLButtonElement>('#banner-open-details-button'),
  $acceptSelectedButton: document.querySelector<HTMLButtonElement>('#details-accept-selected-button'),
  $rejectAllButton: document.querySelector<HTMLButtonElement>('#details-reject-all-button'),
  // widget buttons
  $widgetButton: document.querySelector<HTMLButtonElement>('#cookiewidget'),
  $widgetCloseButton: document.querySelector<HTMLButtonElement>('#widget-close-buttton'),
  $widgetRejectAllButton: document.querySelector<HTMLButtonElement>('#widget-reject-all-button'),
  $widgetChangeConsentButton: document.querySelector<HTMLButtonElement>('#widget-change-consent-button'),
  // switchers
  $switchers: document.querySelectorAll<HTMLDivElement>('.manage-cookies__switcher'),
  $analyticalSwitcher: document.querySelector<HTMLDivElement>('#analytical-switcher'),
  $marketingSwitcher: document.querySelector<HTMLDivElement>('#marketing-switcher'),
  // widget icons
  $widgetStatisticsIcon: document.querySelector<HTMLLIElement>('#cookiewidget-statistics-icon'),
  $widgetMarketingIcon: document.querySelector<HTMLLIElement>('#cookiewidget-marketing-icon'),
  // widget details
  $widgetConsentDate: document.querySelector<HTMLParagraphElement>('#cookiewidget-consent-date'),
  $widgetConsentID: document.querySelector<HTMLParagraphElement>('#cookiewidget-consent-id'),
  // states
  areSetBannerEventListeners: false,
  areSetWidgetEventListeners: false,
  isSetBannerDisplayHandler: false,
  // utils
  consent: window.Cookiebot?.bulkconsent ? window.Cookiebot?.bulkconsent : window.Cookiebot?.consent,
  consentUTC: window.Cookiebot?.bulkconsent?.utc
    ? window.Cookiebot?.bulkconsent?.utc
    : window.Cookiebot?.consentUTC?.getTime(),
  consentID: Number(window.Cookiebot?.bulkconsent?.ticket)
    ? window.Cookiebot?.bulkconsent.ticket
    : window.Cookiebot?.consentID,
  forcedInteractionOptions: cookieService.getItem('cookie_force_interaction'),

  setVariables(): void {
    cookieConsent.consent = window.Cookiebot?.bulkconsent ? window.Cookiebot?.bulkconsent : window.Cookiebot?.consent
    cookieConsent.consentUTC = window.Cookiebot?.bulkconsent?.utc
      ? window.Cookiebot?.bulkconsent?.utc
      : window.Cookiebot?.consentUTC?.getTime()
    cookieConsent.consentID = Number(window.Cookiebot?.bulkconsent?.ticket)
      ? window.Cookiebot?.bulkconsent?.ticket
      : window.Cookiebot?.consentID
  },

  setBannerEventListeners(): void {
    cookieConsent.$acceptAllButton?.addEventListener('click', cookieConsent.handleAcceptAllButtonClick)
    cookieConsent.$acceptAllDialogButton?.addEventListener('click', cookieConsent.handleAcceptAllButtonClick)
    cookieConsent.$openDetailsButton?.addEventListener('click', cookieConsent.handleOpenDetailsButtonClick)
    cookieConsent.$acceptSelectedButton?.addEventListener('click', cookieConsent.handleAcceptSelectedButtonClick)
    cookieConsent.$rejectAllButton?.addEventListener('click', cookieConsent.handleRejectAllButtonClick)
    cookieConsent.$switchers.forEach((switcher) => {
      switcher.addEventListener('click', (e) => cookieConsent.handleSwitcherClick(e, switcher))
    })
    cookieConsent.areSetBannerEventListeners = true
  },

  setWidgetEventListeners(): void {
    cookieConsent.$widgetButton?.addEventListener('click', cookieConsent.handleWidgetClick)
    cookieConsent.$widgetCloseButton?.addEventListener('click', cookieConsent.handleWidgetCloseButtonClick)
    cookieConsent.$widgetRejectAllButton?.addEventListener('click', cookieConsent.handleRejectAllButtonClick)
    cookieConsent.$widgetChangeConsentButton?.addEventListener(
      'click',
      cookieConsent.handleWidgetChangeConsentButtonClick,
    )
    cookieConsent.areSetWidgetEventListeners = true
  },

  setSwitchers(): void {
    if (cookieConsent.$analyticalSwitcher)
      cookieConsent.consent.statistics
        ? cookieConsent.$analyticalSwitcher.classList.add('active')
        : cookieConsent.$analyticalSwitcher.classList.remove('active')
    if (cookieConsent.$marketingSwitcher)
      cookieConsent.consent.marketing
        ? cookieConsent.$marketingSwitcher.classList.add('active')
        : cookieConsent.$marketingSwitcher.classList.remove('active')
  },

  setWidgetIcons(): void {
    if (cookieConsent.$widgetStatisticsIcon)
      cookieConsent.setWidgetIcon(cookieConsent.$widgetStatisticsIcon, cookieConsent.consent.statistics)
    if (cookieConsent.$widgetMarketingIcon)
      cookieConsent.setWidgetIcon(cookieConsent.$widgetMarketingIcon, cookieConsent.consent.marketing)
  },

  setWidgetIcon($icon: HTMLLIElement, isApproved: boolean): void {
    const [toAddClass, toRemoveClass] = isApproved ? ['icon-check', 'icon-cancel'] : ['icon-cancel', 'icon-check']
    if ($icon.classList.contains(toRemoveClass)) $icon.classList.replace(toRemoveClass, toAddClass)
  },

  setWidgetDetails(): void {
    if (cookieConsent.$widgetConsentDate) cookieConsent.$widgetConsentDate.innerText = cookieConsent.getConsentDate()
    if (cookieConsent.$widgetConsentID) cookieConsent.$widgetConsentID.innerText = cookieConsent.consentID
  },

  setIsForcedInteraction(): void {
    cookieConsent.forcedInteractionOptions = cookieService.getItem('cookie_force_interaction')
  },

  getConsentDate(): string {
    const lang = getCurrentLanguage()
    const date = new Date(cookieConsent.consentUTC).toLocaleString(lang, {
      dateStyle: 'long',
      timeStyle: 'long',
      timeZone: 'Poland',
    })
    return date
  },

  setBannerDisplayHandler(): void {
    // https://www.cookiebot.com/en/developer/#h-event-handling
    window.addEventListener('CookiebotOnDialogDisplay', cookieConsent.handleBannerDisplay)
    cookieConsent.isSetBannerDisplayHandler = true
  },

  handleOnLoad(isLoadingDelay: boolean): void {
    cookieConsent.handleForceInteraction()

    setTimeout(() => {
      cookieConsent.setVariables()

      if (!window.Cookiebot?.bulkconsent && !window.Cookiebot?.consented) {
        if (!isLoadingDelay) cookieConsent.handleBannerDisplay()
        else {
          cookieConsent.forcedInteractionOptions
            ? cookieConsent.handleBannerDisplay()
            : setTimeout(cookieConsent.handleBannerDisplay, 3500)
        }
      } else if (window.Cookiebot?.bulkconsent && !window.Cookiebot?.consented) {
        cookieConsent.handleAcceptSelectedButtonClick()
      } else {
        cookieConsent.handleWidgetDisplay()
      }
    }, 0)
  },

  handleBannerDisplay(): void {
    cookieConsent.setVariables()
    if (cookieConsent.$bannerContainer) cookieConsent.$bannerContainer.hidden = false
    // preferences are necessary
    if (window.Cookiebot && !!cookieConsent.consent) {
      cookieConsent.consent.preferences = true
    }
    if (!cookieConsent.areSetBannerEventListeners) cookieConsent.setBannerEventListeners()
    window.Cookiebot?.consented ? cookieConsent.showDialog() : cookieConsent.showBanner()
  },

  handleCookiesAccept(): void {
    if (!cookieConsent.isSetBannerDisplayHandler) cookieConsent.setBannerDisplayHandler()
    if (window.Cookiebot?.consented) {
      if (cookieConsent.forcedInteractionOptions != 'inactive')
        cookieService.setItem('cookie_force_interaction', 'inactive', 'Lax', 90)
      cookieConsent.hideBanner()
      cookieConsent.hideDialog()
      cookieConsent.hideWidgetDialog()
      cookieConsent.hideBackdrop()
      cookieConsent.handleWidgetDisplay()
    } else {
      window.Cookiebot?.renew()
    }
  },

  handleWidgetDisplay(): void {
    cookieConsent.setVariables()
    cookieConsent.showWidget()
  },

  handleAcceptAllButtonClick(): void {
    window.Cookiebot?.submitConsent()
  },

  handleRejectAllButtonClick(): void {
    cookieConsent.consent.statistics = false
    cookieConsent.consent.marketing = false

    window.Cookiebot?.submitCustomConsent(
      cookieConsent.consent.preferences,
      cookieConsent.consent.statistics,
      cookieConsent.consent.marketing,
    )
  },

  handleOpenDetailsButtonClick(): void {
    cookieConsent.hideBanner()
    cookieConsent.showDialog()
  },

  handleAcceptSelectedButtonClick(): void {
    window.Cookiebot?.submitCustomConsent(
      cookieConsent.consent.preferences,
      cookieConsent.consent.statistics,
      cookieConsent.consent.marketing,
    )
  },

  handleSwitcherClick(e: Event, switcher: HTMLDivElement): void {
    e.stopPropagation()
    e.preventDefault()
    switcher.classList.toggle('active')

    switch (switcher.id) {
      case 'analytical-switcher':
        cookieConsent.consent.statistics = !cookieConsent.consent.statistics
        break
      case 'marketing-switcher':
        cookieConsent.consent.marketing = !cookieConsent.consent.marketing
    }
  },

  handleWidgetClick(): void {
    if (cookieConsent.$widgetDialog) cookieConsent.$widgetDialog.hidden = !cookieConsent.$widgetDialog.hidden
  },

  handleWidgetCloseButtonClick(): void {
    cookieConsent.hideWidgetDialog()
  },

  handleWidgetChangeConsentButtonClick(): void {
    cookieConsent.hideWidgetDialog()
    cookieConsent.hideWidget()
    window.Cookiebot?.renew()
  },

  handleForceInteraction(): void {
    const pageSlug = new URL(document.URL).pathname
    const slugsWithoutForceInteractionOnFirstVisit = ['/eur-pln/', '/usd-pln/', '/chf-pln/', '/gbp-pln/']

    cookieConsent.setIsForcedInteraction()

    switch (cookieConsent.forcedInteractionOptions) {
      case 'active':
        cookieConsent.showBackdrop()
        break
      case 'inactive':
        cookieConsent.hideBackdrop()
        break
      default:
        cookieService.setItem('cookie_force_interaction', 'active', 'Lax', 90)
    }

    let isForceInteraction = cookieConsent.forcedInteractionOptions !== 'inactive'

    slugsWithoutForceInteractionOnFirstVisit.forEach((slug) => {
      if (pageSlug.includes(slug) && isForceInteraction) {
        isForceInteraction = false
      }
    })

    if (isForceInteraction) cookieConsent.showBackdrop()
  },

  showDialog(): void {
    cookieConsent.setSwitchers()
    cookieConsent.$bannerContainer?.setAttribute('data-template', 'popup')
    cookieConsent.showBackdrop()
    if (cookieConsent.$dialog) cookieConsent.$dialog.hidden = false
  },

  hideDialog(): void {
    if (cookieConsent.$dialog) cookieConsent.$dialog.hidden = true
    cookieConsent.$bannerContainer?.setAttribute('data-template', 'banner')
  },

  showBanner(): void {
    if (cookieConsent.$banner) cookieConsent.$banner.hidden = false
  },

  hideBanner(): void {
    if (cookieConsent.$banner) cookieConsent.$banner.hidden = true
  },

  showWidgetDialog(): void {
    if (cookieConsent.$widgetDialog) cookieConsent.$widgetDialog.hidden = false
  },

  hideWidgetDialog(): void {
    if (cookieConsent.$widgetDialog) cookieConsent.$widgetDialog.hidden = true
  },

  showWidget(): void {
    cookieConsent.setWidgetIcons()
    cookieConsent.setWidgetDetails()
    if (!cookieConsent.areSetWidgetEventListeners) cookieConsent.setWidgetEventListeners()
    if (cookieConsent.$widgetContainer) cookieConsent.$widgetContainer.hidden = false
  },

  hideWidget(): void {
    if (cookieConsent.$widgetContainer) cookieConsent.$widgetContainer.hidden = true
  },

  showBackdrop(): void {
    if (cookieConsent.$backdrop) cookieConsent.$backdrop.hidden = false
  },

  hideBackdrop(): void {
    if (cookieConsent.$backdrop) cookieConsent.$backdrop.hidden = true
  },
  init(isLoadingDelay = false): void {
    // https://www.cookiebot.com/en/developer/#h-event-handling
    window.addEventListener('CookiebotOnAccept', cookieConsent.handleCookiesAccept)
    cookieConsent.$cookiebotScript?.addEventListener('load', () => cookieConsent.handleOnLoad(isLoadingDelay))
  },
}

window.cookieConsent = cookieConsent

export default cookieConsent
